body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* :root {
  --mouse-x: -50;
  --mouse-y: -50;
} */

/* .mouse::after {
  counter-reset: mouse-x var(--mouse-x) mouse-y var(--mouse-y);
  content: counter(mouse-x) "px, "counter(mouse-y) "px";
} */

/* .mouse::before {
  content: 'mouse position';
  font-weight: bold;
} */

/* .mouse {
  position: fixed;
  top: 0;
  left: 0;
  -webkit-transform: translate(calc(var(--mouse-x) * 1px), calc(var(--mouse-y) * 1px));
  transform: translate(calc(var(--mouse-x) * 1px), calc(var(--mouse-y) * 1px));
  width: 5em;
  height: 5em;
  background: #619be7;
  color: #fff;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  line-height: 2;
  border-radius: 100%;
  will-change: transform;
  z-index: 99999;
} */